<template>
  <section class="view">
    <img class="title width header" src="../assets/header.jpg" />
    <p class="ca">
      CA: HcFAYN1jSWsU8VmSQVgoRwq1i6ovxSX6yvD788Kqmoon
    </p>

    <section class="socials-section">
      <a href="https://x.com/monk_sol_" target="BLANK">
        <svg
          viewBox="0 0 24 24"
          aria-label="X"
          role="img"
          class="socials"
          fill="darkgrey"
        >
          <g>
            <path
              d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
            ></path>
          </g>
        </svg>
      </a>
      <a
        href="https://dexscreener.com/solana/hcfayn1jswsu8vmsqvgorwq1i6ovxsx6yvd788kqmoon"
        target="BLANK"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          fill="darkgrey"
          fill-rule="evenodd"
          viewBox="0 0 252 300"
          focusable="false"
          class="socials"
        >
          <path
            d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"
          ></path>
          <path
            d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"
          ></path>
        </svg>
      </a>
      <a href="https://t.me/MONKEBIZZNIZZSOL" target="BLANK">
        <svg
          fill="darkgrey"
          class="socials"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.122 10.040c0.006-0 0.014-0 0.022-0 0.209 0 0.403 0.065 0.562 0.177l-0.003-0.002c0.116 0.101 0.194 0.243 0.213 0.403l0 0.003c0.020 0.122 0.031 0.262 0.031 0.405 0 0.065-0.002 0.129-0.007 0.193l0-0.009c-0.225 2.369-1.201 8.114-1.697 10.766-0.21 1.123-0.623 1.499-1.023 1.535-0.869 0.081-1.529-0.574-2.371-1.126-1.318-0.865-2.063-1.403-3.342-2.246-1.479-0.973-0.52-1.51 0.322-2.384 0.221-0.23 4.052-3.715 4.127-4.031 0.004-0.019 0.006-0.040 0.006-0.062 0-0.078-0.029-0.149-0.076-0.203l0 0c-0.052-0.034-0.117-0.053-0.185-0.053-0.045 0-0.088 0.009-0.128 0.024l0.002-0.001q-0.198 0.045-6.316 4.174c-0.445 0.351-1.007 0.573-1.619 0.599l-0.006 0c-0.867-0.105-1.654-0.298-2.401-0.573l0.074 0.024c-0.938-0.306-1.683-0.467-1.619-0.985q0.051-0.404 1.114-0.827 6.548-2.853 8.733-3.761c1.607-0.853 3.47-1.555 5.429-2.010l0.157-0.031zM15.93 1.025c-8.302 0.020-15.025 6.755-15.025 15.060 0 8.317 6.742 15.060 15.060 15.060s15.060-6.742 15.060-15.060c0-8.305-6.723-15.040-15.023-15.060h-0.002q-0.035-0-0.070 0z"
          ></path>
        </svg>
      </a>
    </section>

    <p class="width" style="margin-bottom: 30px; margin-top: 30px">
      We love Elon Musk.
    </p>
    <p class="width" style="margin-bottom: 30px; margin-top: 30px">
      So when he tweeted about the game character he plays for Path of Exile 2...
    </p>

    <img class="logo" alt="MONK tweet" src="../assets/tweet.png" />
    <p class="width" style="margin-bottom: 30px; margin-top: 30px">
      $MONK was born.  
    </p>


    <section class="meme-section">
      <h1 class="meme-title">MEME GALLERY</h1>
      <img class="meme" alt="MONK meme" src="../assets/meme1.png" />
      <img class="meme" alt="MONK meme" src="../assets/meme2.webp" />
      <img class="meme" alt="MONK meme" src="../assets/meme3.jpg" />      
      <p class="width" style="margin-bottom: 30px; color: black">
        Legal Disclaimer: $MONK is a meme coin with no intrinsic value or
        expectation of financial return. $MONK is for entertainment purposes
        only.
      </p>
    </section>
  </section>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
.hover:hover {
  color: darkgrey !important;
}

.header {
  border-radius: 25px;
  border: 5px solid darkred;
  box-shadow: 3px 3px 10px red;
}

p {
  color: darkred;
}

.logo {
  width: 50%;
  max-width: 600px;
  border-radius: 5%;
  border: 5px solid darkred;
  box-shadow: 3px 3px 10px red;
}

.socials {
  width: 40px;
}

.telegram {
  width: 55px;
  margin-bottom: 4px;
}

.socials-section {
  display: flex;
  flex-direction: row;
  gap: 10%;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.meme {
  width: 40%;
  max-width: 400px;
  margin-bottom: 25px;
  border-radius: 5%;
  border: 5px solid black;
  box-shadow: 3px 3px 10px black;
}

.title {
  margin-top: 25px;
}

.meme-title {
  font-size: 20px;
}

.width {
  width: 60%;
  max-width: 800px;
}

.view {
  background-color: black;
  height: min-content;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.meme-section {
  background-color: darkgrey;
  width: 100%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: darkgrey ridge 3px;
}

p {
  font-size: 15px;
}

@media only screen and (max-width: 400px) {
  .logo {
    width: 85%;
  }

  .meme {
    width: 85%;
  }

  .title {
    width: 90%;
    margin: 5px;
  }

  .width {
    width: 90%;
  }

  p {
    font-size: 20px;
  }

  .ca {
    font-size: 12px;
  }

  .telegram {
    width: 58px;
  }
}

@media only screen and (min-width: 620px) {
  p {
    font-size: 20px;
  }

  .meme-title {
    font-size: 40px;
  }

  .socials-section {
    gap: 20%;
  }

  .socials {
    width: 60px;
  }

  .telegram {
    width: 75px;
  }
}
</style>
